<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="user-edit-tab-info">
    <div class="vx-row">
      <div class="ml-4" v-if="data_local.students.length < 1">
        No students added yet ! Add new one !
      </div>
      <div v-for="(student, index) in data_local.students" :key="index" class="vx-col w-full md:w-1/2">

        <vx-card class="mt-4">
          <p><u><b>Student #{{index+1}}</b></u></p>
            <vs-input class="w-full mt-4" label="Name" v-model="student.name" v-validate="'required'" data-vv-as="name" :name="'institute'+index" data-vv-scope="basic"/>
            <span class="text-danger text-sm"  v-show="errors.has('basic.institute'+index)">{{ errors.first('basic.institute'+index) }}</span>

            <vs-input class="w-full mt-4" label="Level of study" v-model="student.levelOfStudy" v-validate="'required'" data-vv-as="levelOfStudy" :name="'title'+index" data-vv-scope="basic"/>
            <span class="text-danger text-sm"  v-show="errors.has('basic.title'+index)">{{ errors.first('basic.title'+index) }}</span>

        <!-- <div class="mt-4">
          <label class="vs-input--label">Languages</label>
          <v-select multiple  v-model="student.languages" :clearable="false" label="name" :options="languagesOptions" v-validate="'required'" data-vv-as='language' data-vv-validate-on="blur" :name="'language'+index" data-vv-scope="basic"/>
          <span class="text-danger text-sm"  v-show="errors.has('language'+index)">{{ errors.first('language'+index) }}</span>
        </div> -->
        
<div class="mt-4 vs-con-input w-full">
          <label class="vs-input--label">Languages</label>
          <treeselect 
            search-nested 
            class="w-full normal" 
            :flat="true" 
            v-model="student.languagesId" 
            :multiple="true" 
            :options="languagesOptions"  
            :normalizer="normalizer"
            v-validate="'required'" data-vv-validate-on="blur" name="language">
          </treeselect>
          <div slot="value-label" slot-scope="{ node }">{{ node.label }}</div>
          <span class="text-danger text-sm"  v-show="errors.has('language')">{{ errors.first('language') }}</span>
</div>
        

            <vs-button color="danger" class="ml-auto mt-4" type="border" @click="remove_student(index)">- Remove Student</vs-button>

        </vx-card>
      </div>
    </div>

 <vs-popup classContent="popup-example"  title="Add Student" :active.sync="popupActivo3">
    <vs-input class="w-full mt-4" label="Name" v-model="newStudent.name" v-validate="'required'" data-vv-as="name" data-vv-validate-on="blur" name="titlew" />
          <span class="text-danger text-sm"  v-show="errors.has('titlew')">{{ errors.first('titlew') }}</span>

          <vs-input class="w-full mt-4" label="Level of study" v-model="newStudent.levelOfStudy" v-validate="'required'" data-vv-as="level of study" data-vv-validate-on="blur" name="company" />
          <span class="text-danger text-sm"  v-show="errors.has('company')">{{ errors.first('company') }}</span>

        <!-- <div class="mt-4">
          <label class="vs-input--label">Languages</label>
          <v-select multiple  v-model="newStudent.languages" :clearable="false" label="name" :options="languagesOptions" v-validate="'required'" data-vv-as='language' data-vv-validate-on="blur" :name="'language'"/>
          <span class="text-danger text-sm"  v-show="errors.has('language')">{{ errors.first('language') }}</span>
        </div>       -->
<div class="mt-4 vs-con-input w-full">
          <label class="vs-input--label">Languages</label>
          <treeselect 
            search-nested 
            class="w-full normal" 
            :flat="true" 
            v-model="newStudent.languagesId" 
            :multiple="true" 
            :options="languagesOptions"  
            :normalizer="normalizer"
            v-validate="'required'" data-vv-validate-on="blur" name="language">
          </treeselect>
          <div slot="value-label" slot-scope="{ node }">{{ node.label }}</div>
          <span class="text-danger text-sm"  v-show="errors.has('language')">{{ errors.first('language') }}</span>
</div>

          <vs-button color="success" class="ml-auto mt-4" @click="add_student"> Add student</vs-button>

  </vs-popup>
    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" type="border" @click="open_add_student_popup">+ Add Student</vs-button>
          <vs-button class="ml-4 mt-2" @click="save_changes">Save Changes</vs-button>
          <!-- <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data">Reset</vs-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
 // import the component
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    vSelect,
    flatPickr,Treeselect
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
     
    normalizer(node) {
      return {
        id: node._id,
        label: node.name,
        children: node.children,
      }
    },
      data_local: JSON.parse(JSON.stringify(this.data)),
      popupActivo2: false,
      popupActivo3: false,
      newStudent:{
          name: '',
          levelOfStudy: '',
          languages: [],
          languagesId:[]
      },
      selectedLanguages:[],
      languagesOptions:[],
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any()
    },
  },
  methods: {
    ...mapActions('tutor', ['updateTutorById','getLanguages']),
    open_add_student_popup(){
      this.popupActivo3 = true
      this.newStudent = {
          name: '',
          languages: [],
          levelOfStudy: ''
      }
    },
    add_student(){
      this.data_local.students.push(this.newStudent)
      this.popupActivo3 = false
    },
    remove_student(index){
      this.data_local.students.splice(index, 1)
    },
    async save_changes() {
      // if(!this.validateForm) return
      let isValid = await this.$validator.validateAll('basic')
      if(!isValid){
        this.$vs.notify({
          title: 'Error',
          text: 'Validation Failed',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }
      let tutorId = this.$route.params.userId
      let tutorData = {
        students: this.data_local.students
      }
      
      if(this.data_local.students.length > 0 && this.data_local.students[0].name){
        tutorData.profileCreated = true
        tutorData.students.map(function(student){
          student.languages = student.languagesId
        })
      }
      else{
        tutorData.profileCreated = false
      }
      this.updateTutorById({tutorId, tutorData}).then(res => { //can update both user and tutor
        this.$vs.notify({
          title: 'Update',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      }).catch(err => {
        this.$vs.notify({
          title: 'Update',
          text: 'Update Failed !',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      })
      // Here will go your API call for updating data
      // You can get data in "this.data_local"
    },
    reset_data() {
      this.data_local = Object.assign({}, this.data)
    }
  },
  mounted(){
    this.getLanguages().then(res => {
      this.languagesOptions = res.data.data.docs
    })
    
  }
}
</script>
